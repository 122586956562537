<template>
  <div v-if="groupVisibility">
    <v-scale-transition origin="center center">
      <ConcentricCircles
        v-show="markerVisible"
        class="hotspot-circle-map-marker"
        :style="markerPosition"
      />
    </v-scale-transition>

    <v-btn
      v-if="isMarkerFromTour == true"
      :color="chosenPrimaryColor"
      class="map-marker details-button white--text"
      :style="markerPosition"
      :aria-label="ariaLabel"
      @mouseenter="showCircle = false"
      @mouseleave="showCircle = true"
      @focus="mapHotspotFocus()"
      @click="$emit('setIndex')"
    >
      <v-icon class="marker-icon" v-if="icon !== null">{{ icon }}</v-icon>
      <span class="d-inline-block text-center marker-title">{{ title }}</span>
    </v-btn>

    <v-btn
      v-else
      :color="chosenPrimaryColor"
      class="map-marker details-button white--text"
      :style="markerPosition"
      :aria-label="ariaLabel"
      :data-testid="'hotspot-button' + id"
      @mouseenter="showCircle = false"
      @mouseleave="showCircle = true"
      @focus="mapHotspotFocus()"
    >
      <v-icon class="marker-icon" v-if="icon !== null">{{ icon }}</v-icon>
      <span class="d-inline-block text-center marker-title">{{ title }}</span>
    </v-btn>
  </div>
</template>

<script>
import { loadModules } from "esri-loader";
import ConcentricCircles from "../panorama/ConcentricCircles.vue";

export default {
  name: "MapMarkerArcHtml",
  props: [
    "id",
    "coordinates",
    "colour",
    "icon",
    "mapCenter",
    "mapArc",
    "title",
    "arialabel",
    "markerVisible",
    "markerType",
    "currZoomLevel",
    "zoomMarkerFlag",
    "isMarkerFromTour",
    "layerData",
    "hotspotGroupData",
  ],
  data: () => ({
    screenXY: undefined,
    currentFocusIndex: 0,
    showCircle: true,
    markerVisibility: true,
    chosenPrimaryColor: null,
    groupVisibility: null,
  }),

  components: { ConcentricCircles },

  mounted() {
    this.markerObject = undefined;
    this.handler = undefined;
    this.point = undefined;
    this.configureHotspotGroups();
  },
  created() {
    const self = this;

    loadModules(["esri/geometry/Point"], {
      css: true,
    }).then(([Point]) => {
      self.point = new Point({
        longitude: self.coordinates[0],
        latitude: self.coordinates[1],
      });
    });
  },
  beforeDestroy() {
    if (this.handler !== undefined) this.handler.remove();

    if (this.markerObject !== undefined)
      this.markerLayer.remove(this.markerObject);
  },
  computed: {
    secondaryColor() {
      return this.$vuetify.theme.themes.light.secondary;
    },
    markerPosition() {
      if (this.screenXY === undefined) {
        console.log("No XY");
        return "display:none";
      }

      let screenY = this.screenXY.y - 18;
      let screenX = this.screenXY.x;
      // console.log("XY Found: " + screenX + "x " + screenY + "y");
      return (
        "z-index:1; display:inline-block; top: " +
        screenY +
        "px; left: " +
        screenX +
        "px;"
      );
    },
    ariaLabel() {
      return this.arialabel;
    },
    visibleHotspotGroups() {
      return this.$store.getters["wagtail/getVisibleHotspotGroups"];
    },
  },
  watch: {
    secondaryColor: {
      handler(newVal, oldVal) {
        if (
          this.hotspotGroupData &&
          this.hotspotGroupData.primary_colour_override != null
        ) {
          this.chosenPrimaryColor =
            this.hotspotGroupData.primary_colour_override;
        } else {
          this.chosenPrimaryColor = newVal;
        }
      },
    },
    mapCenter: {
      handler(newVal, oldVal) {
        // console.log("Map Center Watcher Triggered");
        this.updatedScreenXY();
      },
      deep: true,
    },
    visibleHotspotGroups(newValue, oldValue) {
      // Your code here. This function will run whenever the getter's value changes.
      this.checkGroupVisibility();
    },
  },
  methods: {
    configureHotspotGroups() {
      if (this.hotspotGroupData != null) {
        console.log("[HSG]", this.hotspotGroupData);

        if (this.hotspotGroupData && this.hotspotGroupData.primary_colour_override != null) {
          this.chosenPrimaryColor =
            this.hotspotGroupData.primary_colour_override;
        } else {
          this.chosenPrimaryColor = this.colour;
        }
        if (this.hotspotGroupData && this.hotspotGroupData.visible_by_default == true) {
          this.groupVisibility = true;
        } else {
          this.groupVisibility = false;
        }
      } else {
        this.chosenPrimaryColor = this.colour;
        this.groupVisibility = true;
      }
    },
    checkGroupVisibility() {
      const isIdMatched = this.$store.getters[
        "wagtail/getVisibleHotspotGroups"
      ].some((groupNumber) => groupNumber === this.hotspotGroupData.id);
      if (isIdMatched) {
        // Logic for when the ID is found in the array
        console.log("HSG ID matched!");
        this.groupVisibility = true;
      } else {
        // Logic for when the ID is not found in the array
        console.log(
          "HSG ID not matched",
          this.hotspotGroupData.id,
          this.$store.getters["wagtail/getVisibleHotspotGroups"]
        );
        this.groupVisibility = false;
      }
    },
    updatedScreenXY: function () {
      if (
        this.point !== undefined &&
        this.mapArc !== undefined &&
        this.mapArc.view !== undefined
      ) {
        if (this.layerData) {
          loadModules(["esri/geometry/Point"], {
            css: true,
          }).then(([Point]) => {
            const point = new Point({
              longitude: this.layerData.lng,
              latitude: this.layerData.lat,
            });
            this.screenXY = this.mapArc.view.toScreen(point);
          });
        } else {
          this.screenXY = this.mapArc.view.toScreen(this.point);
        }
        // console.log("Updated XY");
      } else {
        console.log("Failed to update XY");
      }
    },
    nextFocus() {
      const elements = document.querySelectorAll(".map-marker");
      this.currentFocusIndex++;
      this.currentFocusIndex = this.currentFocusIndex % elements.length;
      elements[this.currentFocusIndex].focus();
    },
    prevFocus() {
      const elements = document.querySelectorAll(".map-marker");
      this.currentFocusIndex--;
      if (this.currentFocusIndex < 0)
        this.currentFocusIndex = elements.length - 1;
      this.currentFocusIndex = this.currentFocusIndex % elements.length;
      elements[this.currentFocusIndex].focus();
    },
    mapHotspotFocus() {
      this.$emit("focused");
    },
  },
};
</script>

<style></style>
