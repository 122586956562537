<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      v-if="data.type !== 'external_link' && data.type !== 'internal_page_link'"
      :class="`elevation-${hover ? 12 : 2}`"
      class="mx-auto transition-swing"
            :min-height="cardHeight"

      @click="$emit('click')"
    >
  
  <!-- {{$vuetify.breakpoint.mdAndUp ? '280px' : $vuetify.breakpoint.smAndUp ? '200px' : '100px'}} -->
      <v-img
        :src="thumbnail"
        class="fill-space"
      >
        <v-icon color="white" class="pa-2 text--shadow">{{icon}}</v-icon>
      </v-img>
      <v-card-title class="pa-4"><div class="header-class subtitle-2">{{ title }}</div></v-card-title>
      <v-card-text v-if="caption !== ''">{{ caption }}</v-card-text>
      <v-card-text v-if="accessibility_text !== ''">{{accessibility_text}}</v-card-text>

    </v-card>
    <v-card
      v-else-if="data.type == 'external_link' || data.type == 'internal_page_link' "
      :class="`elevation-${hover ? 12 : 2}`"
      id="test-cards"
      :min-height="cardHeight"
      class="mx-auto transition-swing"
      @click="redirect_url(data.type)"
    >
      <v-img
        :src="thumbnail"
        class="fill-space"
      >
        <v-icon color="white" class="pa-2 text--shadow">{{icon}}</v-icon>
      </v-img>
      <v-card-title class="pa-4"><div class="header-class subtitle-2">{{ title }}</div></v-card-title>
      <v-card-text v-if="caption !== ''">{{ caption }}</v-card-text>
      <v-card-text v-if="accessibility_text !== ''">{{accessibility_text}}</v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "CardGalleryBlockCard",
  props: ["data", "cardHeight"],
  mounted(){
    console.log(this.data, this.cardHeight ,"card gall data.vue")
  },
  computed: {
    thumbnail() {
      if (this.data.value.thumbnail) 
      {
        if (this.data.value.thumbnail.thumbnail && !this.data.value.thumbnail.full_image ){
          console.log('triggered here thmbnail')
          return this.data.value.thumbnail.thumbnail
        }
        else if(this.data.value.thumbnail.thumbnail && this.data.value.thumbnail.full_image) {
          return this.data.value.thumbnail.file
        }
        return this.data.value.thumbnail
      }
      return "/pdf-image.png";
    },
    icon() {
      if (this.data.type === 'document')
        return 'mdi-file-document'
      if (this.data.type === 'image')
        return 'mdi-image'
      if (this.data.type === 'video')
        return 'mdi-youtube'
      return ''
    },
    title() {
      if (this.data.value.title)
        return this.data.value.title
      if (this.data.value.page.title)
        return this.data.value.page.title
      return ""
    },
    caption() {
      if (this.data.value.caption)
        return this.data.value.caption
      return ""
    },
    accessibility_text() {
      if (this.data.value.accessibility_text)
        return this.data.value.accessibility_text
      return ""
    }

  },
  methods:{
    redirect_url(data){
      if(data == 'external_link'){
      window.location.href = this.data.value.url
      }else{
        //If the page type is internal trim the -api on the html_url
      const dirtyUrl = this.data.value.page.meta.html_url
      const cleanUrl = dirtyUrl.replace("-api", "")

      window.location.href = cleanUrl
      }
    }
  }
};
</script>

<style scoped>
.document-bg {
  background-color:lightgrey;
}

.text--shadow {
  text-shadow: 1px 2px 5px black;
  opacity: 0.75;
}

.header-class{
    white-space: nowrap ;
    word-break: normal;
    overflow: hidden ;
    text-overflow: ellipsis;
}
 

</style>