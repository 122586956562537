<template>
  <LightboxContentLayout :accessibilityText="data.accessibility_text" :ariaDescription="ariaDescription"
    :captionText="data.caption" :accessibilityTextFlag="accessibilityTextFlag"
    >
    <v-img 
     role="img"
    :style="{
      width: '100%',
      height: '100%',
    }" :src="image" :contain="true" :title="data.alternative_text" :aria-describedby="this.ariaDescription"
     >
    </v-img>
  </LightboxContentLayout>
</template>
<script>

import LightboxContentLayout from "./LightboxContentLayout.vue"

export default {
  name: "LightboxImageCard",

  data: () => ({
    dialog: true,
    isMobile: false
  }),
  props: ["data", "C", "accessibilityTextFlag", "ariaDescription"],
  components: { LightboxContentLayout },
  mounted() {
  },
  methods: {
  },
  computed: {
    image() {
      if (this.data && this.data.file) return this.data.file;
      return undefined;
    },
  },
};
</script>


<style scoped>
</style>