<template>
  <div>
    <Sidebar :pageData="pageData" class="map-sidebar"></Sidebar>
    <div :class="panoClass">
      <Marzipano
        :key="tourIndex"
        typeOf="equirect"
        :data="currentMedia.value"
        :minYaw="minYaw"
        :maxYaw="maxYaw"
        :ready="true"
      >
      </Marzipano>
      <div class="pano-nav">
        <div class="slide-group">
          <v-slide-group
            v-model="tourIndex"
            class="pa-0"
            center-active
            show-arrows
            dark
          >
            <v-slide-item
              v-for="(data, index) in pageData.geotagged_media"
              :key="index"
              v-slot="{ active, toggle }"
            >
              <v-card
                light
                :color="active ? 'orange' : 'white'"
                :elevation="active ? 8 : 2"
                class="ma-2 slide-group-card"
                @click="toggle"
              >
                <v-img
                  :src="data.value.thumbnail"
                  class="slide-group-img"
                ></v-img>
                <v-card-text
                  v-if="data.value.alternate_title"
                  class="text-truncate pa-2 text-center text-caption slide-group-title"
                >
                  {{ data.value.alternate_title }}
                </v-card-text>
                <v-card-text
                  v-else-if="!data.value.alternate_title"
                  class="text-truncate pa-2 text-center text-caption slide-group-title"
                >
                  {{ data.value.title }}
                </v-card-text>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </div>
      </div>
    </div>

    <v-scale-transition origin="center center">
      <v-btn class="map-show-btn" fab v-show="!showMap" @click="showMap = true">
        <v-icon> mdi-map </v-icon>
      </v-btn>
    </v-scale-transition>

    <v-scale-transition origin="top right">
      <v-card :class="mapClass" v-show="showMap" elevation="8">
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>Map</v-toolbar-title>
          <v-spacer />

          <v-btn
            icon
            dark
            @click="largeMap = true"
            v-if="pageData.meta.parent !== null && largeMap === false"
            class="map-resize-btns"
          >
            <v-icon>mdi-dock-right</v-icon>
          </v-btn>

          <v-btn
            icon
            dark
            @click="largeMap = false"
            v-if="pageData.meta.parent !== null && largeMap === true"
            class="map-resize-btns"
          >
            <v-icon>mdi-picture-in-picture-top-right</v-icon>
          </v-btn>

          <v-btn
            icon
            dark
            @click="showMap = false"
            v-if="pageData.meta.parent !== null"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <MapArc
          class="esri-map"
          v-if="pageData !== undefined"
          ref="map"
          :mapZoom="pageData.zoom"
          :mapCenter="[
            currentMedia.value.longitude,
            currentMedia.value.latitude,
          ]"
          :webScene="pageData.esri_map.web_scene_id"
          :filterJson="$store.state.map.layerFilter"
          :layerOptions="$store.state.map.layerOptions"
          :tokens="pageData.tokens"
          :aria-label="pageData.accessible_label"
          @ready="mapReady()"
          :portalItemType="pageData.esri_map.portal_item_type"
          :portalItemId="pageData.esri_map.portal_item_id"
          @mapLoadStart="loading = true"
          @mapLoadEnd="loading = false"
          :showSearch="false"
          :showBasemap="false"
          :showLegend="false"
          @mapMoved="handleMapMoved"
        >
          <div
            v-for="(data, index) in pageData.geotagged_media"
            :key="data.value.file"
          >
            <!-- renders all Panorama view map marker (map marker on mini map)-->
            <MapMarkerArcHtml
              v-if="!isCurrent(index)"
              :mapArc="$refs.map"
              :id="index"
              icon="mdi-panorama"
              aria-label="button-man1"
              :isMarkerFromTour="true"
              :title="data.value.title"
              :mapCenter="mapCenter"
              :coordinates="[data.value.longitude, data.value.latitude]"
              :colour="secondaryColor"
              @setIndex="setTourIndex(index)"
            />
          </div>
          <!-- Current Active Panorama view (map marker on mini map)-->
          <MapMarkerArcHtml
            :key="tourIndex"
            :mapArc="$refs.map"
            :title="currentMedia.value.title"
            :mapCenter="mapCenter"
            icon="mdi-panorama"
            aria-label="button-man2"
            :coordinates="[
              Number(currentMedia.value.longitude),
              Number(currentMedia.value.latitude),
            ]"
            :colour="primaryColor"
          />
        </MapArc>
      </v-card>
    </v-scale-transition>
  </div>
</template>

<script>
// import Streamfield from "../../components/blocks/streamfieldBlock.vue";
import Sidebar from "../../components/sidebar/Sidebar.vue";
import MapArc from "../../components/esrimap/MapArc.vue";
// import MapMarkerArc from "../../components/esrimap/MapMarkerArc.vue";
import Marzipano from "../../components/panorama/Marzipano.vue";
import MapMarkerArcHtml from "../../components/esrimap/MapMarkerArcHtml.vue";

export default {
  name: "MapVirtualTourPage",
  props: {
    pageData: Object,
    pageChildren: Array,
    model: undefined,
    showTitle: { type: Boolean, default: true },
  },
  components: { Sidebar, MapArc, Marzipano, MapMarkerArcHtml },
  data: () => ({
    showGeotaggedImage: true,
    page: 1,
    tourIndex: 0,
    showMap: true,
    largeMap: false,
    mapCenter: [152.849434, -25.301246],
    panorama: {
      title: "R0010206.JPG",
      file: "http://localhost:8000/media/original_images/R0010206.JPG",
      width: 6720,
      height: 3360,
      file_size: 8436137,
      thumbnail:
        "http://localhost:8000/media/images/R0010206.2e16d0ba.fill-240x240.jpg",
      caption: "",
    },
  }),
  mounted() {
    console.log(this.pageData, this.$refs.map.mapCenter, "Mounted Map Center");
  },
  computed: {
    mapClass() {
      if (this.showGeotaggedImage)
        if (this.largeMap) return "map-card-halfscreen";
        else return "map-card";
      return "map";
    },
    minYaw() {
      return 0;
    },
    maxYaw() {
      return 0;
    },
    panoClass() {
      if (this.showGeotaggedImage) return "pano-geotagged";
      return "pano";
    },
    currentMedia() {
      return this.pageData.geotagged_media[this.tourIndex.toString()];
    },
    isFirstPano() {
      return this.tourIndex <= 0;
    },
    isLastPano() {
      return this.tourIndex - 1 >= this.tourLength;
    },
    // tourIndex() {
    //   return this.page - 1;
    // },
    tourLength() {
      return Object.keys(this.pageData.geotagged_media).length;
    },
    primaryColor() {
      return this.$vuetify.theme.themes.light.primary;
    },
    secondaryColor() {
      return this.$vuetify.theme.themes.light.secondary;
    },
  },
  watch: {
    tourIndex: function () {
      this.zoomToCurrentMarker();
    },
  },
  methods: {
    handleMapMoved(evt) {
      if (this.accessibilityMarkerZoom) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.$emit("mapMoved");
        }, 500);
      }
      this.mapCenter = [evt.center.latitude, evt.center.longitude];
      console.log("VTMap Moved Current Map Center: " + this.mapCenter);
    },
    setTourIndex(index) {
      console.log("setTourIndex");
      // Page starts from an index of 1 to be compatible with the vuetify pagination widget
      // this.page = Number(index) + 1;
      this.tourIndex = Number(index);
    },
    isCurrent(index) {
      return this.tourIndex.toString() === index.toString();
    },
    markerClicked(item) {
      console.log("MarkerClicked");
      console.log(item);
    },
    mapReady: function () {
      let self = this;
      setTimeout(function () {
        self.zoomToCurrentMarker();
      }, 2000);
    },

    zoomToCurrentMarker: function () {
      if (this.pageData !== undefined) {
        var center = [
          Number(this.currentMedia.value.longitude),
          Number(this.currentMedia.value.latitude),
        ];
        this.$refs.map.zoomToCenter(center, 20);
      }
    },
    viewChanged(value) {
      console.log(value);
    },
  },
};
</script>

<style scoped>
.slide-group {
  background-color: rgba(0, 0, 0, 0.5);
}

.pano-nav {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.map-sidebar {
  position: absolute;
  top: 0px;
  left: 0px;
  /* width: 344px; */
  width: 350px;
  height: 100%;
}

.map {
  position: absolute;
  top: 0px;
  left: 500px;
  right: 0px;
  height: 100%;
}

.map-card {
  position: absolute;
  top: 10px;
  width: 400px;
  height: 300px;
  right: 10px;
}

.map-card-halfscreen {
  position: absolute;
  top: 10px;
  left: 50%;
  margin-left: 175px;
  bottom: 162px;
  right: 10px;
}

.map-show-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.esri-map {
  position: absolute;
  top: 48px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: unset;
  height: unset;
}

.pano {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
}

.pano-geotagged {
  position: absolute;
  top: 0px;
  left: 350px;
  right: 0px;
  height: 100%;
}

.map-legend {
  display: none;
}

.slide-group-img {
  width: 150px;
  height: 100px;
}

@media (max-width: 600px) {
  .slide-group-img {
    width: 75px;
    height: 50px;
  }

  .slide-group-title {
    display: none;
  }

  .map-resize-btns {
    display: none;
  }

  .map-sidebar {
    height: 50%;
    width: 100%;
    top: 50%;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }

  .map {
    height: 50%;
    width: 100%;
    left: 0px;
    right: 0px;
    bottom: 50%;
  }

  .map-legend {
    display: block;
  }

  .pano-geotagged {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 50%;
  }

  .map-card {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    width: unset;
    height: unset;
    top: 50%;
    bottom: 0px;
  }

  .map-card-halfscreen {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    width: unset;
    height: unset;
    margin-left: unset;
    top: 50%;
    bottom: 0px;
  }
}
</style>
