var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"go_gridItem",class:_vm.item.tile_button_style !== '' ? 'go_gridItem-' + _vm.item.tile_button_style : '',style:({
  backgroundImage: 'url(' + _vm.item.image.file + ')',
  backgroundSize: 'cover',

}),attrs:{"aria-label":_vm.item.accessibility_label}},[_c('div',{style:({
    background: 'rgba(0,0,0,0)',
    cursor: 'pointer',
    display: 'flex'
  }),on:{"click":_vm.openLightbox}},[(_vm.item.image_only_tile === false)?_c('span',{staticClass:"text-body",style:({
      color: _vm.item.title_color,
      display: 'flex',
      margin: '0 auto',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',

    })},[_c('span',{style:({ 
      backgroundColor: 'rgba(79, 79, 79, 0.5)', 
      display: 'flex', 
      justifyContent: 'center', 
      width: '100%', 
      fontSize: '40px', 
      fontWeight: 'bold' })},[(_vm.item.page_icon)?_c('v-icon',{style:({ height: '16px', width: '16px', color: _vm.item.title_color, marginRight: '10px' })},[_vm._v(_vm._s(_vm.item.page_icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.item.title)+" ")],1)]):_vm._e()]),(_vm.showLightbox)?_c('LightboxGallery',{attrs:{"content":_vm.item.content,"startIndex":0,"showLightbox":_vm.showLightbox},on:{"closed":_vm.closeLightbox}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }