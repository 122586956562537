import Vue from "vue";
import VueMeta from "vue-meta";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
// import VueAnalytics from 'vue-ua'
import VueGtag from "vue-gtag";
//import focus looping tool
import VueFocusLoop from "@vue-a11y/focus-loop";

import { TiptapVuetifyPlugin } from "tiptap-vuetify";
// don't forget to import CSS styles
import "tiptap-vuetify/dist/main.css";

// Vuetify's CSS styles
import "../public/css/global-style.css";
import "../public/css/custom.css"

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GA_ID,
    params: {
      send_page_view: false,
    },
  },
});

Vue.use(VueMeta, TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "mdi",
});

// Import our generic global components
Vue.component("LightboxGallery", () => import("./components/lightbox/LightboxGallery.vue"));
Vue.component("StreamfieldBlock", () => import("./components/blocks/streamfieldBlock.vue"));
Vue.component("WebPageLegacy", () => import("./views/wagtail/WebPageLegacy.vue"));


Vue.use(VueFocusLoop);

new Vue({
  vuetify,
  router: router,
  store: store,
  render: (h) => h(App),
}).$mount("#app");

new Vue({
  created() {
    const html = document.documentElement; // returns the html tag
    html.setAttribute("lang", "en");
  },
});

if(process.env.NODE_ENV === 'production') {

    console.log = () => {};
  
  }