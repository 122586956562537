var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
    width: '100%',
    height: '100%',
  })},[_c('Marzipano',{attrs:{"typeOf":"equirect","data":_vm.data.panorama}}),(_vm.hasCaption)?_c('div',{staticClass:"caption-div",style:({
      width: '100%',
      height: '15%',
    })},[(_vm.hasCaption)?_c('v-container',{staticClass:"pa-2 lb-caption",attrs:{"fluid":""}},[_c('v-row',{staticClass:"fill-height row",attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"caption-col",attrs:{"cols":"12","sm":"12","md":"12","lg":"10","xl":"8","justify":"center","align":"center"}},[_c('div',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.data.caption))])])],1)],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }