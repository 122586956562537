var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{style:({ overflow: 'hidden' }),attrs:{"name":"rendered-image","src":_vm.data.location_render_image.file,"alt":_vm.data.location_render_image.alternative_text,"height":"100%","width":"100%"},on:{"load":function($event){return _vm.clearLoading()}}}),_vm._l((this.imageHotspots),function(hotspot){return _c('div',[_c('v-scale-transition',{attrs:{"origin":"center center"}},[_c('ConcentricCircles',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],staticClass:"hotspot-circle-map-marker",style:({
              position: 'absolute',
              top: hotspot.top + '%',
              left: hotspot.left + '%',
            })})],1),_c('v-btn',{key:hotspot.title,staticClass:"details-button white--text secondary",style:({
            position: 'absolute',
            top: hotspot.top + '%',
            left: hotspot.left + '%',
            pointerEvents: 'all'
          }),on:{"mouseenter":function($event){_vm.showCircle = false},"mouseleave":function($event){_vm.showCircle = true},"click":() => _vm.handleClick(hotspot)}},[(hotspot.menu_icon !== null)?_c('v-icon',{staticClass:"marker-icon"},[_vm._v(_vm._s(hotspot.menu_icon))]):_vm._e(),_c('span',{staticClass:"d-inline-block text-center marker-title"},[_vm._v(_vm._s(hotspot.title))])],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }