<template>
  <div
  :aria-label="item.accessibility_label"
  class="go_gridItem" 
  :class="item.tile_button_style !== '' ? 'go_gridItem-' + item.tile_button_style : ''" 
  :style="{
    backgroundImage: 'url(' + item.image.file + ')',
    backgroundSize: 'cover',

  }">
    <div @click="openLightbox" :style="{
      background: 'rgba(0,0,0,0)',
      cursor: 'pointer',
      display: 'flex'
    }">
      <span class="text-body" v-if="item.image_only_tile === false" :style="{
        color: item.title_color,
        display: 'flex',
        margin: '0 auto',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',

      }">
      <span :style="{ 
        backgroundColor: 'rgba(79, 79, 79, 0.5)', 
        display: 'flex', 
        justifyContent: 'center', 
        width: '100%', 
        fontSize: '40px', 
        fontWeight: 'bold' }">
          <v-icon v-if="item.page_icon" :style="{ height: '16px', width: '16px', color: item.title_color, marginRight: '10px' }">{{ item.page_icon }}</v-icon>
          {{ item.title }}
      </span>

     
      </span>
    </div>

    <LightboxGallery v-if="showLightbox" :content="item.content" :startIndex="0" @closed="closeLightbox"
      :showLightbox="showLightbox" />
  </div>
</template>
<script>

export default {
  name: "TileButtonBlock",

  props: [
    "item",
    "data"
  ],

  data: () => ({
    showLightbox: false,
  }),

  methods: {
    openLightbox() {
      this.showLightbox = true;
    },

    closeLightbox(event) {
      this.showLightbox = false;
    },
  }
}
</script>
<style>

</style>