<template>
  <div
    @keydown.up.exact.prevent="testFunc()"
    style="z-index: 3; background-color: white"
    class="scrollable"
    data-testid="map-sidebar"
    id="sidebar-container"
  >
    <v-toolbar dark color="primary lighten-1" elevation="0">
      <v-btn tabindex="-1" icon dark @click="back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-divider vertical class="pl-2" />
      <v-toolbar-title class="pl-4">
        <!-- <v-icon class="pl-3 pr-3">{{ pageData.menu_icon }}</v-icon> -->
        {{ pageData.title }}</v-toolbar-title
      >
      <v-spacer />
      <!-- <v-btn icon dark>
        <v-icon>mdi-share-variant</v-icon>
      </v-btn> -->
    </v-toolbar>

    <v-container class="pb-0">
      <v-row v-for="(block, i) in pageData.description" :key="i">
        <v-col>
          <Streamfield :block="block" />
        </v-col>
      </v-row>
    </v-container>
    <!-- <v-container>
      <v-row v-for="(child, i) in childPages" :key="i">
        <v-col class="pt-0">
          <v-btn block outlined rounded color="secondary"><v-icon left>{{child.menu_icon}}</v-icon> {{child.title}}</v-btn>
          
        </v-col>
      </v-row>
    </v-container> -->
    <!-- <PageShortcutBlock :icon="child.menu_icon" :title="child.title" /> -->

    <v-container class="pb-0" v-if="childPages.length > 0">
      <v-row>
        <v-col class="pb-3">
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <v-alert type="info" color="secondary" border="left" class="mb-0">
            Click on the topics below for more information
          </v-alert>
        </v-col>
      </v-row>
      <v-row v-for="(menuItem, i) in childPages" :key="i">
        <v-col class="pb-0">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                :elevation="hover ? 4 : 1"
                @click="clickChild(menuItem)"
                :style="
                  getBorderStyle(
                    getPrimaryColour(menuItem.primary_colour_override)
                  )
                "
              >
                <v-card-text class="text-subtitle-1 card-text"
                  ><v-icon
                    class="mr-4"
                    :color="getPrimaryColour(menuItem.primary_colour_override)"
                    >{{ menuItem.menu_icon }}</v-icon
                  >{{ menuItem.title }}</v-card-text
                >
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-6 pb-0">
          <v-divider />
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row v-if="pageData.next_map !== null">
        <v-col class="pb-0">
          <v-btn
            block
            color="primary lighten-1"
            @click="gotoPage(pageData.next_map.html_url)"
            >Next - {{ pageData.next_map.title }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row
        v-if="
          pageData.prev_map !== null &&
          pageData.root_map.id !== pageData.prev_map.id
        "
      >
        <v-col class="pb-0">
          <v-btn
            block
            color="primary lighten-1"
            @click="gotoPage(pageData.prev_map.html_url)"
            >Prev - {{ pageData.prev_map.title }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row
        v-if="
          pageData.root_map !== null && pageData.root_map.id !== pageData.id
        "
      >
        <v-col>
          <v-btn
            block
            color="primary lighten-1"
            @click="gotoPage(pageData.root_map.html_url)"
            >Home - {{ pageData.root_map.title }}</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import SidebarPage from "./SidebarPage.vue";
// import AddComment from "./AddComment.vue"
// import ViewComment from "./ViewComment.vue"
import Streamfield from "../../components/blocks/streamfieldBlock.vue";
// import PageShortcutBlock from "../../components/blocks/pageShortcutBlock.vue";

export default {
  name: "Sidebar",
  data: () => ({
    items: [
      { text: "Example Expanded 1", icon: "mdi-car" },
      { text: "Example Expander 2", icon: "mdi-car" },
      { text: "Example Expander 3", icon: "mdi-car" },
    ],
  }),
  props: {
    pageData: Object,
  },
  mounted() {
  
  },
  components: {
    Streamfield,
    // PageShortcutBlock,
    // SidebarPage,
    // AddComment,
    // ViewComment
  },
  computed: {
    locations() {
      return [
        {
          id: 1,
          title: "Location 1",
          entrances: ["Entrance 1", "Entrance 2"],
          exits: ["Exit 1", "Exit 2"],
        },
        {
          id: 2,
          title: "Location 2",
          entrances: ["Entrance 3", "Entrance 4"],
          exits: ["Exit 3", "Exit 4"],
        },
        {
          id: 3,
          title: "Location 3",
          entrances: ["Entrance 5", "Entrance 6"],
          exits: ["Exit 5", "Exit 6"],
        },
      ];
    },
    count() {
      return this.$store.state.map.currentPage;
    },
    childPages() {
      let pages = [];
      for (let child of this.pageData.children) {
        if (child.type === "mappage" || child.type === "mapvirtualtourpage")
          pages.push(child);
      }
      return pages;
    },
  },
  watch: {
    count() {
      this.$el.scrollTop = 0;
    },
    selectLocation: {
      handler() {
        if (this.selectLocation) {
          this.locationOptions = this.selectLocation.value.selected_location;
        }
      },
      immediate: true,
    },
  },
  methods: {
    back() {
      console.log("Back");
      this.$router.back();
      // this.$router.push(
      //   new URL(this.pageData.meta.parent.meta.html_url).pathname
      // );
    },
    gotoPage(url) {
      this.$router.push(new URL(url).pathname);
    },
    getMenuUrl(htmlUrl) {
      return new URL(htmlUrl).pathname;
    },
    testFunc() {
      console.log("triggered from sidebar");
    },
    getPrimaryColour(childColour) {
      if (childColour !== null) return childColour;
      return this.$vuetify.theme.themes.light.primary;
    },
    getSecondaryColour(childColour) {
      if (childColour !== null) return childColour;
      return this.$vuetify.theme.themes.light.secondary;
    },
    getBorderStyle(colour) {
      return "border-left: 8px solid " + colour;
    },
    logger(text) {
      console.log(text);
    },
    clickChild(child) {
      this.$router.push(new URL(child.html_url).pathname);
    },
    updateSiblings() {
      //Reset the prev/next pages
      this.prevPage = undefined;
      this.nextPage = undefined;

      let returnNext = false;
      if (this.pageData.siblings.length > 1) {
        let prevSibling = undefined;
        for (let sibling of this.pageData.siblings) {
          console.log(sibling);
          //Assign the next page
          if (returnNext) {
            this.nextPage = sibling;
            returnNext = false;
          }

          if (sibling.id === this.pageData.id) {
            returnNext = true;

            //Assign the prev page
            this.prevPage = prevSibling;
          }

          prevSibling = sibling;
        }
      }
    },
  },
};
</script>

<style scoped>
.scrollable {
  overflow-y: auto;
  overflow-x: none;
}

.map-legend {
  display: none;
}

.card-text {
  color: rgba(0, 0, 0, 1) !important;
}

@media (max-width: 600px) {
  .map-legend {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }

  .header {
    display: none;
  }
}
</style>
