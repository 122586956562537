<template>
  <div style="display: flex; justify-content: center;">

    <div :style="{ minWidth: data.grid_width + '%' }">
      <div class="gridOverflow go-actionIcon ssg fs ssgdim" :style="{
        '--itemMinWidth': data.minimum_tile_width ? data.minimum_tile_width  + 'px' : 210 + 'px',
        '--gridGap': data.grid_gap ? data.grid_gap + 'px': '10px'
        }">
          <TileButtonBlock v-for="(item, index) in data.tile_buttons" :key="index" :item="item" :data="data"></TileButtonBlock>
      </div>
    </div>
  </div>
</template >

<script>

import TileButtonBlock from "./tileButtonBlock.vue"

export default {
  name: "TileButtonGridBlock", 
  props: ["data"],
  components: {
    TileButtonBlock
  }
} 
</script>

<style scoped></style>
