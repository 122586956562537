<template>
  <v-hover v-slot:default="{ hover }">
    <v-card 
      flat
      @click="$emit('click')"
      :class="`elevation-${hover ? 6 : 0}`"
      class="mx-auto transition-swing"
      tabindex="-1"
    >
      <v-card-text class="pb-0 text-center">
        <v-btn class="streamfield-btn" tabindex="0"  aria-hidden="false"  :aria-label="title" fab x-large color="secondary">
          <v-icon x-large>{{ icon }}</v-icon>
        </v-btn>
      </v-card-text>
      <v-card-text class="text-center subtitle-1">
        {{ title }}
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "PageShortcutBlock",
  props: ["icon", "title"],
};

</script>

<style scoped>
.streamfield-btn:focus-visible {
  outline: 4px dashed darkorange;
}
</style>