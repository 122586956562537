import MapService from '../services/map.service';

export const map = {
  namespaced: true,
  state: {
    mapComments: [
    ],
    currentPage: undefined,
    currentPopup: undefined,
    esriTokens: [],
    newComment: undefined,
    activeTool: 'map',
    showLegend: false,
    layerFilter: null,
    layerOptions: {},
    sceneid: "c5c3b634295c4474bfa03e5a0af96f3c",
    webSceneId: "undefined",
    accessibilityMarkerZoom: null,
    renderLocation: null,
    selectedLocation: null,
    hotspotGroupSelectedLocation: null,

    journey: {
      startLocation: null,
      stopLocation: null,
      entrance: null,
      exit: null
    },
    resetMap: false,
    resetJourney: false,
    resetLocation: false,
    resetRenderLayer: false,
    precinct: null,
    backupPrecinct: null,
    rightSidebarZoomLevel:null,
    scrollZoom: false,
    groupLocation: null

  },

  actions: {
    getComments({ commit }, mapPageId) {
      return MapService.getComments(mapPageId).then(
        response => {
          commit('getCommentsSuccess', response.data);
          return Promise.resolve(response.data);
        },
        error => {
          commit('getCommentsFailure');
          return Promise.reject(error);
        }
      );
    },
    placeComment({ state, commit }, screenpoint) {
      let data = {
        "lng": screenpoint[0],
        "lat": screenpoint[1],
        "name": "",
        "comment": "",
        "sentiment": "happy",
        "map": state.currentPage.id
      }
      commit('addComment', data);
    },

    getEsriTokens({ commit }) {
      return MapService.getEsriTokens().then(
        response => {
          commit('getEsriTokenSuccess', response.data);
          return Promise.resolve(response.data);
        },
        error => {
          commit('getEsriTokenFailure');
          return Promise.reject(error);
        }
      );
    },

    getPage({ commit }, id) {
      return MapService.getPage(id).then(
        response => {
          commit('getPageSuccess', response.data);
          return Promise.resolve(response.data);
        },
        error => {
          commit('getPageFailure');
          return Promise.reject(error);
        }
      );
    },

    getPopup({ commit }, id) {
      return MapService.getPage(id).then(
        response => {
          commit('getPopupSuccess', response.data);
          return Promise.resolve(response.data);
        },
        error => {
          commit('getPopupFailure');
          return Promise.reject(error);
        }
      );
    },

    savePage({ state }) {
      let data = { title: "Hello There" }
      return MapService.savePage(state.currentPage.id, data).then(
        response => {
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    setPageTitle({ commit }, data) {
      commit('setPageTitle', data)
    },

    addKeyStat({ commit }, data) {
      commit('addKeyStat', data)
    },
    removeKeyStat({ commit }, index) {
      commit('removeKeyStat', index)
    }
  },

  mutations: {
    setScrollZoom (state, data) {
      state.scrollZoom = data
    },
    setRightSidebarZoomLevel (state, data) {
      state.rightSidebarZoomLevel = data
    },
    setGroupLocation(state, data) {
      state.groupLocation = data
    },
    setPrecinct(state, data) {
      state.precinct = data
    },
    setBackupPrecinct(state, data) {
      state.backupPrecinct = data
    },
    setResetMap(state, data) {
      state.resetMap = data
    },
    setResetMap(state, data) {
      state.resetMap = data
    },
    setResetJourney(state, data) {
      state.resetJourney = data
    },
    setResetRenderLayer(state, data) {
      state.resetRenderLayer = data
    },
    setJourney(state, data) {
      state.journey = data;
    },
    setResetLocation(state, data) {
      state.resetLocation = data;
    },
    setSelectedLocation(state, data) {      
      state.selectedLocation = data;
    },
    setRenderLocation(state, data) {      
      state.renderLocation = data;
    },
    setHotspotGroupSelectedLocation(state, data) {      
      state.hotspotGroupSelectedLocation = data;
    },
    getCommentsSuccess(state, data) {
      state.mapComments = data;
    },
    getCommentsFailure(state) {
      state.mapComments = [];
    },
    addComment(state, data) {
      state.newComment = data;
    },
    saveComment(state) {
      if (state.newComment !== undefined)
        state.mapComments.push( state.newComment );
      state.newComment = undefined
    },
    cancelComment(state) {
      state.newComment = undefined
    },
    getEsriTokenSuccess(state, data) {
      state.esriTokens = data;
    },
    getEsriTokenFailure(state) {
      state.esriTokens = [];
    },
    getPageSuccess(state, data) {
      state.currentPage = data;
    },
    getPageFailure(state) {
      state.currentPage = undefined;
    },
    getPopupSuccess(state, data) {
      state.currentPopup = data;
    },
    getPopupFailure(state) {
      state.currentPopup = undefined;
    },
    setPageTitle(state, data) {
      state.currentPage.title = data
    },
    addKeyStat(state, data) {
      state.currentPage.key_stats.push(data)
    },
    removeKeyStat(state, index) {
      state.currentPage.key_stats.pop(index)
    },
    setMapTool(state, value) {
      state.activeTool = value
    },
    showLegend(state, value) {
      state.showLegend = value
    },
    setLayerFilter(state, value) {
      state.layerFilter = value
    },
    setLayerOptions(state, value)
    {
      state.layerOptions = value
    },
    accessibilityMarkerZoom (state, payload) {
      state.accessibilityMarkerZoom = payload
    }
  }
};
